import(/* webpackMode: "eager", webpackExports: ["default"] */ "/webui/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Fira_Code\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-mono\"}],\"variableName\":\"fontMono\"}");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"fontPoppins\"}");
;
import(/* webpackMode: "eager" */ "/webui/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-roboto\"}],\"variableName\":\"fontRoboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/webui/src/components/instrumentation/datadog-init.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextUITheme"] */ "/webui/src/providers/nextui.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProviderWrapper"] */ "/webui/src/providers/query-client-provider.tsx");
;
import(/* webpackMode: "eager" */ "/webui/src/styles/globals.css");
